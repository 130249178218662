html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 1rem;
  overflow: auto;
  overscroll-behavior: contain;
  height: 100vh;
}

a,
a:focus,
a:hover {
  color: inherit;
  text-decoration: none;
}

/* Data Grid Style */
.table-condensed > tbody > tr > .active {
  background: #8abf1a !important;
}

.r104f42s700-beta16 {
  --rdg-header-background-color: #ecf1f5;
  border: none;
}
.r104f42s700-beta16:not(.rdg-light) {
  --rdg-background-color: #181d1f;
  --rdg-border-color: rgba(71, 130, 74, 0.15);
  box-shadow: none;
  --rdg-header-background-color: #161819;
}
.c1wupbe700-beta16[aria-selected="true"] {
  outline: none;
}

#detailsGrid > .rdg-row > .rdg-cell {
  z-index: 0 !important;
  padding: 0;
}
#detailsGrid > .rdg-row {
  width: inherit;
}
#detailsGrid > .rdg-row > .rdg-cell-frozen:first-child {
  width: inherit;
  /* grid-column-start: 1; */
  grid-column-end: -1;
}
#detailsGrid > .rdg-row > .rdg-cell:not(:first-child) {
  height: 0px !important;
  width: 0px Im !important;
}

.r104f42s700-beta16:not(.rdg-light) > div > .rdg-row > .rdg-cell {
  box-shadow: 0 0 1px #444;
}

.rdg-cell {
  cursor: text;
  padding: 0 8px;
}
.rdg-cell:focus {
  outline: 1.5px solid #608512;
  border-radius: 2px;
  outline-offset: -1.5px;
  user-select: text !important;
}
.rdg-cell-frozen:focus {
  outline: none !important;
}
.rdg-summary-row {
  z-index: 2010;
  user-select: text !important;
  cursor: auto !important;
}
.rdg-summary-row > .rdg-cell {
  outline: none;
}

.h197vzie700-beta16 > .c1wupbe700-beta16 {
  z-index: 2 !important;
}
.h197vzie700-beta16 > .c1730fa4700-beta16 {
  z-index: 3 !important;
}

::-webkit-calendar-picker-indicator {
  padding-right: 6px;
  filter: invert(83%) sepia(20%) saturate(6522%) hue-rotate(31deg)
    brightness(95%) contrast(80%);
}

/* Scrollbar Style */
::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

/* Track */
::-webkit-scrollbar-track {
  background: inherit;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6c6c6c;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8abf1a;
}

#btn-cell-expander {
  cursor: pointer;
  background: inherit;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%;
  margin-left: -6px;
  opacity: 0.7;
}
#btn-cell-expander:hover {
  color: #66bb6a;
  opacity: 1;
}

#grid-text-css {
  text-align: right;
  margin: 0px;
  margin-top: -2px;
}

#open-order-details-css {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #0288d1;
}
#open-order-details-css:hover {
  color: #66bb6a;
}
#select-all-row,
#select-single-row {
  cursor: pointer;
}

.row-expand-dark {
  background: #121212;
}
.row-expand-light {
  background: #ecf1f5;
}

div[role=columnheader] {
  display: flex;
  width: 100%;
}
div[role=columnheader]::before {
  content: "";
  cursor: col-resize;
  inline-size: 10px;
  inset-block-end: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  position: absolute;
}
div[role=columnheader]::after {
  display: none;
}
.column-header-cls {
  display: inline-flex;
  font-weight: 500;
  line-height: normal !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  appearance: none;
  -moz-appearance: textfield;
}

.MuiPickersCalendarHeader-root {
  margin: 0;
}
.MuiPickersCalendarHeader-label {
  font-size: 14px;
}

.css-1m9pwf3 {
  top: unset;
  left: unset;
}

.MuiPickerStaticWrapper-content {
  background: inherit !important;
}

input[type=time] {
  font-family: Roboto, Droid Sans, sans-serif;
  padding: 4px 8px;
  font-size: 18px;
  border: none;
}
input[type=time]::-webkit-calendar-picker-indicator {
  display: none;
}
input[type=datetime-local] {
  width: 175px;
  background: inherit;
  font-family: Roboto, Droid Sans, sans-serif;
  padding-left: 8px;
  border: 0;
}
input[type=datetime-local]::-webkit-calendar-picker-indicator {
  display: none;
}
input[type=datetime-local]:focus-visible {
  outline: none;
}
input[type=time]:focus-visible {
  outline: none;
}
